<template>
  <div class="container my-5">
    <h1>Contatti</h1>
    <div class="info-contatti">
      <div class="box-contatti">
        <h3><img class="logo-ente-sm" :src="ente.logo" alt=""> {{ ente.nome }}</h3>
        <p>{{ ente.indirizzo }} <br>
        <a :href="`tel:${ente.telefono}`">{{ ente.telefono }}</a><br>
        <a :href="`tel:${ente.email}`">{{ ente.email }}</a><br>
        P.Iva: {{ ente.piva }}
        </p>
        <p>
          <strong>Link utili:</strong>
          <ul v-if="ente.link_sito || ente.link_trasparenza || ente.link_albo">
            <li v-if="ente.link_sito"><a :href="ente.link_sito">Sito istituzionale</a></li>
            <li v-if="ente.link_trasparenza"><a :href="ente.link_sito">Amministrazione trasparente</a></li>
            <li v-if="ente.link_albo"><a :href="ente.link_sito">Albo pretorio</a></li>
          </ul>
        </p>
      </div>
      <div class="box-testo" v-html="ente.testo_contatti"></div>
    </div>
    

  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  computed: {
      ...mapState([
          'ente'
      ]),
  },    
}
</script>